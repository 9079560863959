import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AppBar, ListItemButton, ListItemText, PopoverProps, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { COLOR_TEXT_PRIMARY, GRAY_COLORS } from '../../constants/colors';
import { Paths } from '../../constants/routes';

import { useStore } from '../../hooks/useStore';
import Logo from '../logo/Logo';
import UserAvatar from '../UserAvatar/UserAvatar';
import UserMenu from '../userMenu/UserMenu';
import Flex from '../utils/flex/Flex';

const StyledToolbar = styled(Toolbar)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 600px) {
    min-height: 56px;
    padding: 0 32px;
  }
`;

const TopMenuItemButton = styled(ListItemButton)`
  padding: 0 16px;
  border-radius: 6px;

  & .Mui-selected {
    background-color: ${GRAY_COLORS.GRAY_100};
  }
`;

interface NavigationContainerProps {
  children: React.ReactNode;
}

const NavigationContainer = ({ children }: NavigationContainerProps) => {
  const {
    uiStore: { toggleIsNavigationDrawerExpanded, isNavigationDrawerExpanded, dimensions },
    userStore: { userData },
    localizationStore: { i18next: i18n },
  } = useStore();

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const [anchorMenuElement, setAnchorMenuElement] = useState<PopoverProps['anchorEl']>(null);

  const isSmallScreen = dimensions.small || dimensions.tinySmall || dimensions.tiny;

  useEffect(() => {
    if (isSmallScreen) {
      toggleIsNavigationDrawerExpanded(false);
    }
  }, [isSmallScreen]);

  const handleExpand = (event: { currentTarget: Element }) => {
    setIsMenuExpanded(prevState => !prevState);
    if (!isMenuExpanded) {
      setAnchorMenuElement(event.currentTarget);
    } else {
      setAnchorMenuElement(null);
    }
  };

  const handleCloseMenu = () => {
    setIsMenuExpanded(false);
    setAnchorMenuElement(null);
  };

  return (
    <Box sx={{ overflow: 'hidden', width: '100%' }}>
      <AppBar position={'fixed'}>
        <StyledToolbar>
          <Flex sx={{ gap: '8px' }}>
            <Logo />
            <TopMenuItemButton
              sx={{ marginLeft: '32px' }}
              onClick={() => navigate(Paths.CHAT)}
              selected={pathname.startsWith(Paths.CHAT)}
            >
              <ListItemText
                sx={{ margin: 0, color: pathname.startsWith(Paths.CHAT) ? COLOR_TEXT_PRIMARY : GRAY_COLORS.GRAY_500 }}
              >
                {i18n.t('home.newConversation.chat')}
              </ListItemText>
            </TopMenuItemButton>
            <TopMenuItemButton
              onClick={() => navigate(Paths.PROMPTS_GALLERY)}
              selected={pathname.startsWith(Paths.PROMPTS_GALLERY)}
            >
              <ListItemText
                sx={{
                  margin: 0,
                  color: pathname.startsWith(Paths.PROMPTS_GALLERY) ? COLOR_TEXT_PRIMARY : GRAY_COLORS.GRAY_500,
                }}
              >
                Prompt Gallery
              </ListItemText>
            </TopMenuItemButton>
            {/*Comment knowledge base for now*/}
            <TopMenuItemButton
              onClick={() => navigate(Paths.KNOWLEDGE_BASE)}
              selected={pathname.startsWith(Paths.KNOWLEDGE_BASE)}
            >
              <ListItemText
                sx={{
                  margin: 0,
                  color: pathname.startsWith(Paths.KNOWLEDGE_BASE) ? COLOR_TEXT_PRIMARY : GRAY_COLORS.GRAY_500,
                }}
              >
                {i18n.t('sidebarMenu.knowledgeBase.action')}
              </ListItemText>
            </TopMenuItemButton>
            {/*{userData?.role !== Roles.member && (*/}
            {/*  <TopMenuItemButton*/}
            {/*    onClick={() => navigate(Paths.ADMIN_PORTAL)}*/}
            {/*    selected={pathname.startsWith(Paths.ADMIN_PORTAL)}*/}
            {/*  >*/}
            {/*    <ListItemText>{i18n.t('sidebarMenu.adminPortal.action')}</ListItemText>*/}
            {/*  </TopMenuItemButton>*/}
            {/*)}*/}
          </Flex>
          {/*<AnnounceKitComponent />*/}
          <IconButton size={'small'} onClick={handleExpand} disableRipple>
            <UserAvatar user={userData} sx={{ marginRight: '8px' }} />
            {isMenuExpanded ? (
              <ExpandLessIcon fontSize="small" sx={{ fill: GRAY_COLORS.GRAY_500 }} />
            ) : (
              <ExpandMoreIcon fontSize="small" sx={{ fill: GRAY_COLORS.GRAY_500 }} />
            )}
          </IconButton>
          <UserMenu anchorElement={anchorMenuElement as any} handleCloseMenu={handleCloseMenu} />
        </StyledToolbar>
      </AppBar>

      <Box
        component="main"
        sx={{
          display: 'flex',
          flex: 1,
          transition: theme =>
            theme.transitions.create(['margin', 'width'], {
              easing: isNavigationDrawerExpanded ? theme.transitions.easing.sharp : theme.transitions.easing.easeOut,
              duration: isNavigationDrawerExpanded
                ? theme.transitions.duration.leavingScreen
                : theme.transitions.duration.enteringScreen,
            }),
          marginTop: '56px',
          minHeight: 'calc(100vh - 56px)',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default observer(NavigationContainer);

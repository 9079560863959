import { makeAutoObservable, runInAction } from 'mobx';
import autoBind from 'auto-bind';

import { EMPTY_INTEGRATION_MODEL, IntegrationModel } from '../models/IntegrationModel';
import { IntegrationApi } from '../api/IntegrationApi';

export class IntegrationsStore {
  integrationsApi: IntegrationApi;
  selectedIntegration: IntegrationModel = EMPTY_INTEGRATION_MODEL;
  integrations: IntegrationModel[] = [];

  constructor(integrationsApi: IntegrationApi) {
    this.integrationsApi = integrationsApi;
    makeAutoObservable(this);
    autoBind(this);
  }

  setSelectedIntegration(selectedIntegration: IntegrationModel) {
    runInAction(() => {
      this.selectedIntegration = selectedIntegration;
    });
  }

  async getIntegrations() {
    const integrations = await this.integrationsApi.getIntegrations();

    runInAction(() => {
      this.integrations = integrations;
    });
  }

  async upsertIntegrations({ integrations }: { integrations: IntegrationModel[] }) {
    await this.integrationsApi.upsertIntegrations({ integrations });
    await this.getIntegrations();
  }
}

import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { COLOR_PRIMARY, GRAY_COLORS } from '../../../constants/colors';

import OpenAIDetailsSection from '../../ConversationPage/components/OpenAIDetailsSection';
import { Prompt } from '../../../models/Prompt';
import { sortArrayByInput } from './utils';

interface PromptContentContainerProps {
  prompt: Prompt;
  variablesState?: any;
  setPromptText?: React.Dispatch<React.SetStateAction<string>>;
}

interface TextChunk {
  isVariable: boolean;
  text: string;
}

const PromptContentContainer = ({
  prompt: { recommendedModel, content },
  variablesState,
  setPromptText,
}: PromptContentContainerProps) => {
  const [textChunks, setTextChunks] = useState<TextChunk[]>([]);

  const parsePromptContent = (inputString: string) => {
    const mentionRegex = /\[([^\]]+)\]/g;
    const dropdownRegex = /\{([^\}]+)\}/g;

    const mentionsArray = inputString.match(mentionRegex) || [];
    const dropdownsArray = inputString.match(dropdownRegex);
    // @ts-ignore
    const matches = dropdownsArray?.length ? mentionsArray.concat(dropdownsArray) : mentionsArray;

    if (!matches) {
      return [{ isVariable: false, text: inputString }];
    }

    const sortedMatches = sortArrayByInput(matches, inputString);

    const result = [];
    let lastIndex = 0;

    sortedMatches.forEach((match: string) => {
      const variable = match.replace(/['`]/g, '"').substring(1, match.length - 1);
      const nonVariableText = inputString.substring(lastIndex, inputString.indexOf(match, lastIndex));

      if (nonVariableText) {
        result.push({ isVariable: false, text: nonVariableText });
      }

      result.push({ isVariable: true, text: variable });
      lastIndex = inputString.indexOf(match, lastIndex) + match.length;
    });

    const remainingText = inputString.substring(lastIndex);
    if (remainingText) {
      result.push({ isVariable: false, text: remainingText });
    }

    return result;
  };

  const getTextFromParsedChunks = (chunks: TextChunk[]) => {
    let result = '';

    chunks.forEach(chunk => {
      if (!chunk.isVariable) {
        result += chunk.text;
        return;
      }

      result += variablesState?.[chunk.text] ? variablesState?.[chunk.text] : chunk.text;
    });

    return result;
  };

  useEffect(() => {
    const chunks = parsePromptContent(content);
    const text = getTextFromParsedChunks(chunks);

    setPromptText && setPromptText(text);

    setTextChunks(chunks);
  }, [content, variablesState]);

  return (
    <Container>
      <Header>
        {recommendedModel ? (
          <OpenAIDetailsSection
            model={recommendedModel?.modelVersion}
            accuracy={recommendedModel?.temperature}
            length={recommendedModel?.maxTokens}
          />
        ) : (
          <Typography variant={'body2'} sx={{ fontSize: '12px', color: GRAY_COLORS.GRAY_500 }}>
            No recommended model
          </Typography>
        )}
      </Header>

      <Box sx={{ padding: '16px', display: 'inline' }}>
        {textChunks.map(item => (
          <Typography
            variant={'subtitle1'}
            sx={
              item.isVariable
                ? { display: 'inline', backgroundColor: '#EDF3FE', color: COLOR_PRIMARY }
                : { display: 'inline' }
            }
          >
            {item.isVariable ? variablesState?.[item.text] || item.text : item.text}
          </Typography>
        ))}
      </Box>
    </Container>
  );
};

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 6px;
  border: 1px solid ${GRAY_COLORS.GRAY_2};
  margin-bottom: 24px;
`;

const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  background: #eff1f7;
  min-height: 50px;
  border-radius: 6px 6px 0 0;
  padding: 12px 16px 12px 16px;
`;

export default PromptContentContainer;

export interface ConfigurationModel {
  configName: string;
  url: string;
  token: string;
}

export interface SettingsModel {
  configuration: ConfigurationModel;
}

export interface IntegrationModel {
  id: string;
  organizationId: string;
  category: string;
  name: string;
  icon?: string;
  serviceName: string;
  isActive?: boolean;
  description: string;
  tags?: string;
  settings: SettingsModel;
}

export const EMPTY_INTEGRATION_MODEL = {
  id: '',
  organizationId: '',
  category: '',
  name: '',
  icon: '',
  serviceName: '',
  isActive: false,
  description: '',
  tags: '',
  settings: {
    configuration: {
      configName: '',
      url: '',
      token: '',
    },
  },
};

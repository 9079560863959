import React, { useState } from 'react';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import Box from '@mui/material/Box';

import { useStore } from '../../hooks/useStore';
import Button from '../buttons/Button';
import AddSourceModal from './AddSourceModal';
import { getFileExtensionFromName } from '../../utils/getFileExtensionFromName';
import { isImageFile } from '../../utils/isImageFile';
import { fileStatus } from '../../constants/fileStatus';

const SUPPORTED_EXTENSIONS = ['pdf', 'doc', 'ppt', 'docx', 'pptx', 'csv', 'txt', 'xlx', 'xlsx'];

export const checkIfSupported = (file: File) => {
  if (file.type.includes('image') || isImageFile(file.name)) {
    return true;
  }

  const fileExtension = getFileExtensionFromName(file.name);
  return SUPPORTED_EXTENSIONS.includes(fileExtension);
};

const UploadFile = () => {
  const {
    localizationStore: { i18next: i18n },
    knowledgeBaseStore: { currentKnowledgeBase },
  } = useStore();
  const [showModal, setShowModal] = useState(false);

  return (
    <Box>
      <Button
        onClick={() => setShowModal(true)}
        startIcon={<UploadOutlinedIcon />}
        disabled={currentKnowledgeBase.status !== fileStatus.DONE}
        sx={{
          padding: '9px 16px !important',
          height: '40px !important',
          width: 'fit-content',
          fontWeight: 400,
        }}
      >
        {i18n.t('knowledgeBase.button.addSource')}
      </Button>

      {showModal && <AddSourceModal isOpen={showModal} onClose={() => setShowModal(false)} />}
    </Box>
  );
};

export default UploadFile;

import { Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { CustomSearchBar } from '../../../../components/CustomSearchBar/CustomSearchBar';
import Divider from '../../../../components/Divider/Divider';
import { LoadingSpinner } from '../../../../components/spinner/LoadingSpinner';
import Flex from '../../../../components/utils/flex/Flex';
import { GRAY_COLORS } from '../../../../constants/colors';

import { TimePeriods } from '../../../../constants/timePeriods';

import { useStore } from '../../../../hooks/useStore';
import { Conversation } from '../../../../models/Conversation';
import { filterObjectsByKeys } from '../../../../utils/filterObjectsByKeys';
import { groupObjectsByCreationDate } from '../../../../utils/groupObjectByCreatedAt';
import HistoryItem from '../HistoryTab/components/HistoryItem';
import TimePeriodFilter from '../HistoryTab/components/TimePeriodFilter';

const SharedWithMeTab = ({ activeTab }: { activeTab: number }) => {
  const {
    conversationStore: { sharedWithMe, getSharedWithMe, getNewConversations },
    localizationStore: { i18next: i18n },
  } = useStore();

  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [filteredConversations, setFilteredConversations] = useState<Conversation[]>([]);
  const [timePeriod, setTimePeriod] = useState(TimePeriods.allTime);

  useEffect(() => {
    if (activeTab === 1) {
      (async () => {
        setLoading(true);
        await getSharedWithMe();
        await getNewConversations();
        setLoading(false);
      })();
    }
  }, [activeTab]);

  useEffect(() => {
    (async () => {
      await getNewConversations();
    })();
  }, []);

  useEffect(() => {
    if (!searchValue) {
      setFilteredConversations(sharedWithMe);
      return;
    }

    const newFilteredConversations = filterObjectsByKeys(sharedWithMe, ['content', 'title'], searchValue);

    setFilteredConversations(newFilteredConversations);
  }, [searchValue, sharedWithMe]);

  const MINUTE_MS = 60 * 1000;

  useEffect(() => {
    const interval = setInterval(() => {
      (async () => {
        await getNewConversations();
      })();
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  const memoizedHistoryItems = useMemo(() => {
    const expandedFilters = {
      [TimePeriods.allTime]: TimePeriods.allTime,
      [TimePeriods.today]: TimePeriods.today,
      [TimePeriods.last7Days]: `${TimePeriods.today}-${TimePeriods.last7Days}`,
      [TimePeriods.last30Days]: `${TimePeriods.today}-${TimePeriods.last7Days}-${TimePeriods.last30Days}`,
    };

    const groupedConversations = groupObjectsByCreationDate(filteredConversations);
    const itemsToBeRendered: any[] = [];

    Object.keys(groupedConversations).forEach(key => {
      if (timePeriod !== TimePeriods.allTime && !expandedFilters[timePeriod].includes(key)) {
        return;
      }

      const conversationsInsideGroup: Conversation[] = groupedConversations[key];

      if (Array.isArray(conversationsInsideGroup) && conversationsInsideGroup.length > 0) {
        itemsToBeRendered.push(
          <Flex sx={{ alignItems: 'center' }}>
            <Divider sx={{ flex: 1, opacity: 0.5 }} />
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '21px',
                color: GRAY_COLORS.GRAY_5,
                padding: '0 8px',
              }}
            >
              {i18n.t(`timePeriods.filter.${key}`)}
            </Typography>
            <Divider sx={{ flex: 1, opacity: 0.5 }} />
          </Flex>
        );

        conversationsInsideGroup.forEach(conversation => {
          itemsToBeRendered.push(<HistoryItem conversation={conversation} />);
        });
      }
    });

    return itemsToBeRendered;
  }, [filteredConversations, timePeriod]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Flex
      sx={{
        height: '100%',
        padding: '16px',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Flex>
        <CustomSearchBar
          onChange={setSearchValue}
          onCancelSearch={() => setSearchValue('')}
          placeholder={'Search'}
          sx={{ marginBottom: '24px', flex: 3 }}
        />
        <TimePeriodFilter timePeriod={timePeriod} setTimePeriod={setTimePeriod} />
      </Flex>

      <Virtuoso data={memoizedHistoryItems} itemContent={(_index, item) => item} />
    </Flex>
  );
};

export default observer(SharedWithMeTab);

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { Paths } from '../constants/routes';

import {
  authenticationAndSidebarFilePreviewWrapper,
  authenticationAndSidebarWrapper,
  loginWrapper,
} from './RestrictedPages';
import AuthenticationProvider from '../providers/AuthenticationProvider';
import LoginPage from '../pages/LoginPage';
import HomePage from '../pages/HomePage/HomePage';
import PromptsGalleryPage from '../pages/PromptsGalleryPage/PromptsGalleryPage';
import HistoryPage from '../pages/HistoryPage/HistoryPage';
import KnowledgeBasePage from '../pages/ConversationPage/knowledgeBase/KnowledgeBasePage';
import ProfilePage from '../pages/ProfilePage';
import ConversationPage from '../pages/ConversationPage/ConversationPage';
import MemberSignUpPage from '../pages/SignUpPage/MemberSignUp/MemberSignUpPage';
import KnowledgeBaseFilePreview from '../pages/ConversationPage/knowledgeBase/KnowledgeBaseFilePreview';

const AppRoutes = () => {
  return (
    <Router>
      <AuthenticationProvider>
        <Routes>
          <Route path={Paths.MEMBER_SIGN_UP} element={<MemberSignUpPage />} />
          <Route path={Paths.LOGIN} element={loginWrapper(LoginPage)} />
          <Route path={Paths.INIT} element={loginWrapper(LoginPage)} />
          <Route path={`${Paths.CHAT}/:id`} element={authenticationAndSidebarWrapper(HomePage)} />
          <Route path={Paths.CHAT} element={authenticationAndSidebarWrapper(HomePage)} />
          <Route path={`${Paths.CONVERSATION}/:id`} element={authenticationAndSidebarWrapper(ConversationPage)} />
          <Route path={Paths.PROMPTS_GALLERY} element={authenticationAndSidebarWrapper(PromptsGalleryPage)} />
          <Route path={Paths.HISTORY} element={authenticationAndSidebarWrapper(HistoryPage)} />
          <Route path={Paths.KNOWLEDGE_BASE} element={authenticationAndSidebarWrapper(KnowledgeBasePage)} />
          <Route
            path={`${Paths.KNOWLEDGE_BASE}/team/:id`}
            element={authenticationAndSidebarWrapper(KnowledgeBasePage)}
          />
          <Route
            path={`${Paths.KNOWLEDGE_BASE}/:fileId`}
            element={authenticationAndSidebarFilePreviewWrapper(KnowledgeBaseFilePreview)}
          />
          <Route path={Paths.PROFILE} element={authenticationAndSidebarWrapper(ProfilePage)} />
        </Routes>
      </AuthenticationProvider>
    </Router>
  );
};

export default AppRoutes;

import CloseIcon from '@mui/icons-material/Close';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import { Box, FormControlLabel, IconButton, Radio, RadioGroup } from '@mui/material';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { COLOR_MANDATORY, COLOR_TEXT_PRIMARY, GRAY_COLORS } from '../../constants/colors';

import { useStore } from '../../hooks/useStore';
import Button from '../buttons/Button';
import CustomModal from '../modal/CustomModal';
import Flex from '../utils/flex/Flex';
import FlexColumnStart from '../utils/flex/FlexColumnStart';
import FlexRowSpaceBetween from '../utils/flex/FlexRowSpaceBetween';
import DragAndDropFileInput from '../DragAndDropFileInput/DragAndDropFileInput';
import { File as FileModel } from '../../models/File';
import S3SourceSelectedComponent from './sources/S3SourceSelectedComponent';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ErrorsContainer = styled.div`
  background: rgba(200, 30, 30, 0.15);
  padding: 8px;
  width: 100%;
  border-radius: 4px;
  margin-top: 16px;
`;

const optionStyle = {
  borderRadius: '6px',
  border: '1px solid #E0E2E9',
  padding: '2px 12px 2px 0',
};
const AddSourceModal = ({ isOpen, onClose }: ModalProps) => {
  const {
    localizationStore: { i18next: i18n },
    knowledgeBaseStore: { addFilesToKnowledgeBase, currentKnowledgeBase, uploadInProgress },
    teamStore: { teams, getTeamsByUserOrganization },
    s3FileSystemStore: { s3FilesToAddToKnowledgeBase, isUploadInProgress },
    appState: { fileApi },
  } = useStore();

  const [groupOption, setGroupOption] = useState('Files');
  const [filesToAddToKnowledgeBase, setFilesToAddToKnowledgeBase] = useState<FileModel[]>([]);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    (async () => {
      if (teams.length === 0) {
        await getTeamsByUserOrganization();
      }
    })();
  }, []);

  const handleChangeRadioValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setGroupOption(value);
  };

  const onAddFilesToKnowledgeBase = async () => {
    if (filesToAddToKnowledgeBase.length) {
      await addFilesToKnowledgeBase({
        knowledgeBaseId: currentKnowledgeBase.id,
        files: filesToAddToKnowledgeBase.map(file => ({ id: file.id, skipRedaction: file.skipRedaction })),
      });
    }

    if (s3FilesToAddToKnowledgeBase.length) {
      await addFilesToKnowledgeBase({
        knowledgeBaseId: currentKnowledgeBase.id,
        files: s3FilesToAddToKnowledgeBase.map(file => ({ id: file.id, skipRedaction: file.skipRedaction })),
      });
    }

    if (filesToAddToKnowledgeBase.length || s3FilesToAddToKnowledgeBase.length) {
      fileApi.startProcessingKnowledgeBase(currentKnowledgeBase.id);
    }

    onClose();
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      sx={{ width: '900px', minWidth: '650px', padding: '24px', overflow: 'scroll', maxHeight: '90vh' }}
    >
      <StyledContainer>
        <FlexRowSpaceBetween sx={{ width: '100%' }}>
          <Typography variant={'subtitle1'} fontWeight={'700'}>
            {i18n.t('knowledgeBase.addSource.title')}
          </Typography>

          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </FlexRowSpaceBetween>

        <Box sx={{ margin: '12px 0', width: '100%' }}>
          <Typography variant={'body2'} sx={{ color: GRAY_COLORS.GRAY_700 }}>
            {i18n.t('knowledgeBase.selectSourceType.message')}
          </Typography>

          <RadioGroup
            onChange={handleChangeRadioValue}
            aria-labelledby={'groupOption'}
            name={'groupOption'}
            key={'groupOption'}
            sx={{ marginLeft: '10px', marginTop: '8px' }}
            value={groupOption}
          >
            <Flex sx={{ gap: '8px' }}>
              <FormControlLabel
                value={'Files'}
                control={<Radio checkedIcon={<TripOriginIcon />} />}
                label={'Files'}
                checked={groupOption === 'Files'}
                sx={optionStyle}
                disabled={!!filesToAddToKnowledgeBase.length || !!s3FilesToAddToKnowledgeBase.length}
              />
              <FormControlLabel
                value={'S3'}
                control={<Radio checkedIcon={<TripOriginIcon />} />}
                label={'S3'}
                checked={groupOption === 'S3'}
                sx={optionStyle}
                disabled={!!filesToAddToKnowledgeBase.length || !!s3FilesToAddToKnowledgeBase.length}
              />
              <FormControlLabel
                value={'API'}
                control={<Radio checkedIcon={<TripOriginIcon />} />}
                label={'API'}
                checked={groupOption === 'API'}
                sx={optionStyle}
                disabled
              />
            </Flex>
          </RadioGroup>

          {groupOption === 'Files' && (
            <FlexColumnStart
              sx={{
                marginTop: '16px',
                gap: '12px',
                maxHeight: 'calc(90vh - 244px)',
                overflow: 'scroll',
              }}
            >
              <DragAndDropFileInput setFilesToAddToKnowledgeBase={setFilesToAddToKnowledgeBase} />
            </FlexColumnStart>
          )}

          {groupOption === 'S3' && (
            <FlexColumnStart sx={{ marginTop: '16px', gap: '12px' }}>
              <S3SourceSelectedComponent />
            </FlexColumnStart>
          )}

          {error && (
            <ErrorsContainer>
              <Typography variant="subtitle2" sx={{ color: COLOR_MANDATORY }}>
                {error}
              </Typography>
            </ErrorsContainer>
          )}
        </Box>

        <Flex sx={{ justifyContent: 'flex-end', gap: '16px', width: '100%' }}>
          <Button
            onClick={onClose}
            variant={'outlined'}
            sx={{
              border: `1px solid ${GRAY_COLORS.GRAY_300} !important`,
              color: `${COLOR_TEXT_PRIMARY} !important`,
              width: 'fit-content',
              padding: '9px 16px !important',
            }}
          >
            {i18n.t('common.cancel.action')}
          </Button>

          <Button
            disabled={isUploadInProgress || uploadInProgress}
            onClick={onAddFilesToKnowledgeBase}
            sx={{
              width: 'fit-content',
              padding: '9px 16px !important',
            }}
          >
            {i18n.t('common.done.action')}
          </Button>
        </Flex>
      </StyledContainer>
    </CustomModal>
  );
};

export const StyledContainer = styled.form`
  width: 100%;
  max-height: calc(90vh - 48px) !important;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: column;
`;

export default observer(AddSourceModal);

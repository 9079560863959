import { PiiEntity } from '@aws-sdk/client-comprehend';
import { Attribute as PhiEntity } from '@aws-sdk/client-comprehendmedical';

import { ChatSentiment } from '../constants/chatSentiment';

import { Conversation } from './Conversation';
import { File } from './File';

export interface Chat {
  id: string;
  organizationId: string;
  userId: string;
  message: string;
  isUserChat: boolean;
  wasBlocked: boolean;
  blockedTextIntervals?: (PiiEntity | PhiEntity)[];
  wasWarned: boolean;
  warnedTextIntervals?: (PiiEntity | PhiEntity)[];
  wasAnonymized: boolean;
  anonymizedTextIntervals?: (PiiEntity | PhiEntity)[];
  sentiment?: number;
  createdAt: Date;
  updatedAt: Date;
  conversation?: Conversation;
  files?: File[];
  sourceDocuments?: string[];
  // extra type used for rendering loading on FE
  loading?: boolean;
  error?: string;
}

export const EMPTY_CHAT: Chat = {
  wasAnonymized: false,
  wasWarned: false,
  createdAt: new Date(),
  id: '',
  isUserChat: false,
  message: '',
  error: '',
  organizationId: '',
  updatedAt: new Date(),
  userId: '',
  wasBlocked: false,
};

export interface CreateChatDto {
  conversationId: string;
  message: string;
  promptId?: string;
}

export interface UpdateChatDto {
  sentiment?: ChatSentiment;
}

export interface AddFileToConversationDto {
  conversationId: string;
  files: {
    id: string;
    name: string;
  }[];
}

export enum SupportedAIProvidersEnum {
  open_ai = 'open_ai',
  azure = 'azure',
  google = 'google',
  cohere = 'cohere',
  amazon = 'amazon',
  anthropic = 'anthropic',
  ai21Labs = 'ai21Labs',
  renaissance = 'renaissance',
}

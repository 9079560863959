import React, { useState } from 'react';
import { IconButton, styled, TableCell } from '@mui/material';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import TableRow from '@mui/material/TableRow';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import Typography from '@mui/material/Typography';

import { COLOR_BLACK, GRAY_COLORS } from '../../../constants/colors';

import { useStore } from '../../../hooks/useStore';
import { PrimarySwitch } from '../../Switch/PrimarySwitch';
import { getTextOverflowEllipsisStyles } from '../../typography/utils';
import Flex from '../../utils/flex/Flex';
import { getIconByName, TypeIcon } from '../../knowledgeBase/utils';
import { File as FileModel } from '../../../models/File';

interface FileUploadCardProps {
  setFilesToAddToKnowledgeBase: React.Dispatch<React.SetStateAction<FileModel[]>>;
  file: File;
  documentKey: string;
  progress: number;
  removeFileByKey: (key: string) => void;
}

const FileUploadRowItem = ({
  setFilesToAddToKnowledgeBase,
  file,
  progress,
  removeFileByKey,
  documentKey,
}: FileUploadCardProps) => {
  const {
    appState: { s3DocumentsApi },
  } = useStore();

  const [loading, setLoading] = useState(false);
  const [redactSensitiveData, setRedactSensitiveData] = useState(true);

  const handleRemoveFile = async () => {
    if (!documentKey) {
      return;
    }

    try {
      setLoading(true);
      await s3DocumentsApi.deleteFile(documentKey);
      removeFileByKey(documentKey);
    } catch (e) {
      console.log('e ------------------->> ', e);
    } finally {
      setLoading(false);
    }
  };

  const onChangeRedactSensitiveData = (value: boolean) => {
    setRedactSensitiveData(value);

    setFilesToAddToKnowledgeBase(prevState =>
      prevState.map(file => {
        if (file.key === documentKey) {
          return { ...file, skipRedaction: !value };
        }

        return file;
      })
    );
  };

  return (
    <TableRow sx={{ height: '40px' }}>
      <StyledTableCell>
        <Flex sx={{ alignItems: 'center' }}>
          {progress < 100 ? (
            <UploadFileOutlinedIcon sx={{ marginRight: '16px' }} />
          ) : (
            <TypeIcon src={getIconByName(file.name)} alt={file.name} />
          )}
          <Typography sx={getTextOverflowEllipsisStyles(1)}>{file.name}</Typography>
        </Flex>
      </StyledTableCell>

      <StyledTableCell>
        <Flex sx={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
          <PrimarySwitch
            checked={redactSensitiveData}
            onChange={event => onChangeRedactSensitiveData(event.target.checked)}
          />
          <Typography sx={redactSensitiveData ? {} : { color: GRAY_COLORS.GRAY_6 }}>
            {redactSensitiveData ? 'Yes' : 'No'}
          </Typography>
        </Flex>
      </StyledTableCell>

      <StyledTableCell align={'right'}>
        <IconButton
          disabled={progress < 100}
          sx={{ marginLeft: 'auto', textTransform: 'none', color: COLOR_BLACK }}
          onClick={handleRemoveFile}
        >
          <RemoveCircleOutlineOutlinedIcon sx={{ fontSize: '20px !important', fill: GRAY_COLORS.GRAY_5 }} />
        </IconButton>
      </StyledTableCell>
    </TableRow>
  );
};

const StyledTableCell = styled(TableCell)`
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  background: inherit !important;
  padding-top: 14px;
  padding-bottom: 12px;
`;

export default FileUploadRowItem;

import { MenuItem, Select, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { TimePeriods } from '../../../../../constants/timePeriods';
import { useStore } from '../../../../../hooks/useStore';

interface TimePeriodFilterProps {
  timePeriod: string;
  setTimePeriod: (timePeriod: string) => void;
}

const TimePeriodOptions = [
  {
    value: TimePeriods.allTime,
  },
  {
    value: TimePeriods.today,
  },
  {
    value: TimePeriods.last7Days,
  },
  {
    value: TimePeriods.last30Days,
  },
];

const TimePeriodFilter = ({ timePeriod, setTimePeriod }: TimePeriodFilterProps) => {
  const {
    localizationStore: { i18next: i18n },
  } = useStore();

  return (
    <Select
      sx={{ maxWidth: '180px', minWidth: '120px', marginLeft: '8px', fontSize: '14px', flex: 1 }}
      value={timePeriod}
      renderValue={value => i18n.t(`timePeriods.filter.${value}`)}
      onChange={event => {
        setTimePeriod(event.target.value);
      }}
    >
      {TimePeriodOptions.map(option => {
        return (
          <MenuItem value={option.value} key={option.value}>
            <Typography variant={'subtitle2'}>{i18n.t(`timePeriods.filter.${option.value}`)}</Typography>
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default observer(TimePeriodFilter);

export const COLOR_PRIMARY = '#3871E0';
export const COLOR_SECONDARY = '#D6E4FF';
export const COLOR_TERTIARY = '#F3F4F6';
export const COLOR_QUATERNARY = '#9EA4AD';
export const COLOR_QUINARY = '#A9A9A9';

export const COLOR_TEXT_PRIMARY = '#1b1b1b';
export const COLOR_TEXT_SECONDARY = '#4F5760';
export const COLOR_TEXT_TERTIARY = '#6D737E';
export const COLOR_TEXT_QUATERNARY = '#1B1B1B';
export const COLOR_TEXT_QUINARY = '#3A424F';
export const COLOR_TEXT_BASE = '#212A36';
export const COLOR_TEXT_BASE_PRIMARY = '#6D737E';
export const COLOR_TEXT_LIGHT_CLUE = '#76A9FA';

export const COLOR_WHITE = '#ffffff';
export const COLOR_BLACK = '#000000';

export const COLOR_BORDER_PRIMARY = '#E5E7EB';
export const COLOR_BORDER_SECONDARY = '#D3D6DB';
export const COLOR_BORDER_TERTIARY = '#E5E5E5';
export const COLOR_BORDER_QUARTERNARY = '#EBF2FF';

export const COLOR_ICON = '#3A424F';
export const COLOR_ICON_PRIMARY = '#456DE5';

export const COLOR_MENU_ITEM_SELECTED = '#E4EBFF';

export const COLOR_BUTTON = '#0D1622';
export const COLOR_BUTTON_DELETE = '#D92C2C';
export const COLOR_BUTTON_OUTLINED_HOVER = '#F9FAFB';
export const COLOR_BUTTON_OUTLINED_FOCUSED = '#D3D6DB';
export const COLOR_BUTTON_OUTLINED_DISABLED = '#E5E7EB';
export const COLOR_BUTTON_CONTAINED = '#456DE5';
export const COLOR_BUTTON_CONTAINED_HOVER = '#6690FF';
export const COLOR_BUTTON_CONTAINED_FOCUSED = '#2950D6';
export const COLOR_BUTTON_CONTAINED_DISABLED = '#D3D6DB';
export const COLOR_BUTTON_CONTAINED_ERROR = '#D92C2C';
export const COLOR_BUTTON_CONTAINED_ERROR_HOVER = '#E55050';
export const COLOR_BUTTON_CONTAINED_ERROR_FOCUSED = '#A61919';
export const COLOR_MANDATORY = '#C81E1E';
export const COLOR_MANDATORY_BACKGROUND = '#FDE8E8';
export const COLOR_MEMBER = '#723B13';
export const COLOR_MEMBER_BACKGROUND = '#FDF6B2';
export const COLOR_BUTTON_CONTAINED_ERROR_DISABLED = '#D3D6DB';
export const COLOR_BUTTON_OUTLINED_SUCCESS_TEXT = '#456DE5';
export const COLOR_BUTTON_OUTLINED_SUCCESS_HOVER = '#EBF2FF';
export const COLOR_BUTTON_OUTLINED_SUCCESS_FOCUSED = '#D6E4FF';
export const COLOR_BUTTON_OUTLINED_SUCCESS_DISABLED = '#E5E7EB';

export const COLOR_TABLE_HEADER = '#F9FAFB';

export const COLOR_SUCCESS = '#15803D';
export const COLOR_SUCCESS_05 = '#09B27A';
export const COLOR_SUCCESS_LIGHT = '#F0FDF4';
export const COLOR_WARNING = '#B45309';
export const COLOR_WARNING_LIGHT = '#FFFBEB';
export const COLOR_ERROR = '#DB262C';
export const COLOR_ERROR_TEXT = '#D92C2C';
export const COLOR_ERROR_ICON = '#CA3521';
export const COLOR_ERROR_LIGHT = '#f2edee';
export const COLOR_INACTIVE_LIGHT = '#FFE5E5';

export const COLOR_UTILS_PRIMARY = '#0B2038';
export const COLOR_UTILS_SECONDARY = '#456DE5';
export const COLOR_UTILS_TERTIARY = '#f0f5fd';
export const COLOR_UTILS_QUATERNARY = '#C5CEE0';
export const COLOR_UTILS_QUINARY = '#2950D6';
export const COLOR_UTILS_SUCCESS_PRIMARY = '#CAEED6';
export const COLOR_UTILS_SUCCESS_SECONDARY = '#008C5E';
export const COLOR_UTILS_LINK = '#84A9FF';

export const COLOR_CHART_NORMAL = '#6690FF';
export const COLOR_CHART_WARNING = '#FFD171';
export const COLOR_CHART_ERROR = '#DB262C';

export const COLOR_STATUS_SENT_TO_SERVICE = '#EFE7FD';
export const COLOR_STATUS_SENT_TO_SERVICE_TEXT = '#7930EA';
export const COLOR_SELECT_TEXT = 'rgba(0, 0, 0, 0.6)';

export const COLOR_CHECKED = '#25B85F';

export const COLOR_HELP_PRIMARY = '#E58845';
export const COLOR_HELP_SECONDARY = '#CB0E46';
export const COLOR_HELP_TERTIARY = '#56A84F';
export const COLOR_HELP_QUATERNARY = '#456DE5';

export const COLOR_TIMELINE_LAST_LOCATION = '#64748B';
export const COLOR_TIMELINE_CURRENT_LOCATION = '#F07B11';

export const COLOR_LOCATION_DOT = '#F9D5B2';
export const COLOR_BACKGROUND = '#FFFFFF';
export const COLOR_BACKGROUND_SECONDARY = '#F9FAFB';
export const COLOR_DARK_BACKGROUND = '#0B2038';
export const COLOR_NOTE_BACKGROUND = '#F3F4F6';

export const COLOR_TEAM_PROVECTUS_BACKGROUND = '#EBF5FF';

export const COLOR_DIVIDER = '#D1D5DB';

export const COLOR_LINK = '#7DA9FF';

export const GRAY_COLORS = {
  GRAY_100: '#F3F4F6',
  GRAY_1: '#EFF1F7',
  GRAY_2: '#E0E2E9',
  GRAY_200: '#E5E7EB',
  GRAY_300: '#D1D5DB',
  GRAY_5: '#8F9196',
  GRAY_500: '#6B7280',
  GRAY_700: '#5D5F64',
  GRAY_6: '#76787D',
  GRAY_7: '#5d5f64',
  GRAY_8: '#4E5055',
  GRAY_9: '#2D2E32',
  GRAY_10: '#1B1C20',
};

export const TOAST_COLORS = {
  SUCCESS: '#0E9F6E',
  WARNING: '#E3A008',
  INFO: COLOR_WHITE,
  ERROR: '#F05252',
};

import logo from './logo.svg';

const config = {
  name: 'Add Value Machine',
  domain: 'addvaluemachine',
  logo,
  features: {
    // feature flags
  },
};

export const googleDriveConfig = {
  clientId:
    process.env.GOOGLE_DRIVE_CLIENT_ID || '238656817752-bbatooiqq751jv9fjflp739qtbab7ahv.apps.googleusercontent.com',
  developerKey: process.env.GOOGLE_DRIVE_DEVELOPER_KEY || 'AIzaSyD2wd9qso3jz6WdF-ipfGtf2sng41FmR2U',
};
export default config;

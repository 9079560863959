import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import FullHeightScreenWrapper from '../../components/FullHeightComponentWrapper/FullHeightScreenWrapper';
import { useStore } from '../../hooks/useStore';
import PromptsGalleryTable from './components/PromptsGalleryTable';
import PromptsDetailsCard from './components/PromptsDetailsCard/PromptsDetailsCard';
import AddPromptModal from '../../components/modal/AddPromptModal/AddPromptModal';
import StartNewChatFromPromptModal from '../../components/modal/StartNewChatFromPromptModal';
import ConfirmDeletePromptModal from '../../components/modal/ConfirmDeletePromptModal';

const PromptsGalleryPage = () => {
  const {
    promptsStore: { getPromptsByUser, getPromptsByUserTeams, setEmptyPrompt },
    uiStore: {
      isStartNewConversationFromPromptOpen,
      toggleIsStartNewConversationFromPromptOpen,
      isPromptDetailsCardDisplayed,
    },
    userStore: { getUserInformation },
    modelStore: { findAllOrganizationModels },
  } = useStore();

  const [isAddPromptModalOpen, setIsAddPromptModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDeletePromptModalOpen, setIsDeletePromptModalOpen] = useState(false);

  useEffect(() => {
    (async () => {
      await getPromptsByUserTeams();
      await getPromptsByUser();
      await getUserInformation();
      await findAllOrganizationModels();
    })();
  }, []);

  const handleCloseStartNewChatFromPromptModal = () => {
    toggleIsStartNewConversationFromPromptOpen(false);
    setEmptyPrompt();
  };

  return (
    <FullHeightScreenWrapper
      sx={{ backgroundColor: 'transparent', flexDirection: 'row', height: 'calc(100vh - 56px)' }}
    >
      <PromptsGalleryTable
        containerStyles={{
          width: isPromptDetailsCardDisplayed ? '60%' : '100%',
          marginRight: isPromptDetailsCardDisplayed ? '16px' : '0',
        }}
        setIsAddPromptModalOpen={setIsAddPromptModalOpen}
        setIsEditMode={setIsEditMode}
        setIsDeletePromptModalOpen={setIsDeletePromptModalOpen}
      />
      {isPromptDetailsCardDisplayed && (
        <PromptsDetailsCard
          setIsEditMode={setIsEditMode}
          setIsAddPromptModalOpen={setIsAddPromptModalOpen}
          setIsDeletePromptModalOpen={setIsDeletePromptModalOpen}
        />
      )}
      {isAddPromptModalOpen && (
        <AddPromptModal
          isOpen={isAddPromptModalOpen}
          editMode={isEditMode}
          onClose={() => {
            setIsAddPromptModalOpen(false);
          }}
        />
      )}
      {isStartNewConversationFromPromptOpen && (
        <StartNewChatFromPromptModal
          isOpen={isStartNewConversationFromPromptOpen}
          onClose={handleCloseStartNewChatFromPromptModal}
        />
      )}
      {isDeletePromptModalOpen && (
        <ConfirmDeletePromptModal isOpen={isDeletePromptModalOpen} onClose={() => setIsDeletePromptModalOpen(false)} />
      )}
    </FullHeightScreenWrapper>
  );
};

export default observer(PromptsGalleryPage);

import styled from 'styled-components';

import { SIZES_SMALL, SIZES_TINY_SMALL } from '../../constants/sizes';
import { COLOR_DIVIDER, COLOR_BACKGROUND, COLOR_BACKGROUND_SECONDARY } from '../../constants/colors';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: ${COLOR_BACKGROUND_SECONDARY};

  @media screen and (max-width: ${SIZES_SMALL}) {
    background: ${COLOR_BACKGROUND};
  }
`;

export const Content = styled.div`
  position: absolute;
  top: 90px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 644px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: ${SIZES_SMALL}) {
    width: 100vw;
  }
`;

export const Logo = styled.img`
  width: 106px;
  position: relative;
  bottom: 9vh;

  @media screen and (max-width: ${SIZES_TINY_SMALL}) {
    bottom: 4vh;
  }
`;

export const LoginImage = styled.img`
  width: 104px;
  height: 104px;
  margin: auto;
`;
export const Card = styled.div<{ height?: string; padding?: string; alignitems?: string }>`
  width: 644px;
  height: ${props => props.height || '547px'};
  max-height: 660px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  font-stretch: normal;
  font-style: normal;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: ${props => props.alignitems || 'center'};
  justify-content: space-around;
  padding: ${props => props.padding || '66px 0'};

  @media screen and (max-width: ${SIZES_SMALL}) {
    width: 100vw;
    padding: 66px 12px;
    box-shadow: none;
  }

  @media screen and (max-width: ${SIZES_TINY_SMALL}) {
    height: 480px;
  }
`;

export const CardActionsContainer = styled.div<{ width?: string }>`
  width: ${props => props.width || '265px'};
  @media screen and (max-width: ${SIZES_TINY_SMALL}) {
    margin-top: 12px;
    width: 100%;
  }
`;

export const Divider = styled.div`
  height: 1px;
  background: ${COLOR_DIVIDER};
  width: 100%;
`;

export const StyledUploadContainer = styled.div<{ size: number; imageExists: boolean }>`
  min-width: ${props => props.size}px;
  min-height: ${props => props.size}px;
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
`;

export const HoverImageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: 1;
  border-radius: 6px;
`;

export const UploadImageInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

export const UploadImageLabel = styled.label`
  display: flex;
  cursor: pointer;
  align-items: center;
  position: relative;
`;

export const StyledImage = styled.img`
  height: auto;
  width: 100%;
`;

export const MarginsWrapper = styled.div.attrs((props: { marginBottom: number; marginTop: number }) => props)`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => `${props.marginBottom || 0}px`};
  margin-top: ${props => `${props.marginTop || 0}px`};
`;

import AttachFileIcon from '@mui/icons-material/AttachFile';
import Box from '@mui/material/Box';
import axios from 'axios';
import React, { useRef, useState } from 'react';
import { PickerCallback } from 'react-google-drive-picker/dist/typeDefs';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { useToasts } from 'react-toast-notifications';

import { COLOR_DIVIDER, COLOR_PRIMARY, COLOR_WHITE, GRAY_COLORS } from '../../constants/colors';
import { Paths } from '../../constants/routes';

import { useStore } from '../../hooks/useStore';
import { Conversation } from '../../models/Conversation';
import BlackTooltip from '../../tooltips/BlackTooltip';
import { getDocumentsKey, uploadDocumentSecurely } from '../../utils/documentsS3';
import { uploadDriveFile } from '../../utils/uploadFile';
import GoogleDrivePicker from '../googleDrive/GoogleDrivePicker';
import RedactDataModal from '../modal/RedactDataModal';

const MyInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const MyLabel = styled.label<{ disabled?: boolean }>`
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`;

const IconContainer = styled(Box)<{ disabled?: boolean }>`
  padding: 8px;
  border-radius: 6px;
  border: 1px solid ${GRAY_COLORS.GRAY_300};
  background-color: ${props => (props.disabled ? COLOR_DIVIDER : COLOR_WHITE)};

  display: flex;
  align-items: center;
  justify-content: center;
`;

interface UploadDocumentProps {
  disabled?: boolean;
  disabledMessage?: string;
  assignFilesToConversation: (
    files: {
      id: string;
      name: string;
    }[]
  ) => Promise<Conversation>;
}

const UploadDocument = ({ disabled, disabledMessage, assignFilesToConversation }: UploadDocumentProps) => {
  const {
    userStore: { userData },
    appState: { s3DocumentsApi, fileApi, googleDriveApi },
    conversationStore: {
      setIsUploadInProgress,
      setUploadProgress,
      setUploadingConversationId,
      setCancelUploadToken,
      cancelFileUpload,
      setUploadingFileId,
    },
    fileStore: { createFile },
    localizationStore: { i18next: i18n },
  } = useStore();
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const shouldSkipRedaction = process.env.REACT_APP_SKIP_REDACTION === 'true';

  const inputRef = useRef<HTMLInputElement>(null);
  const [isAskForRedactionModalOpen, setIsAskForRedactionModalOpen] = useState(false);
  const [fileToUpload, setFileToUpload] = useState<File>();

  const onUploadGoogleDriveDocuments = async (googleDriveObject?: { data: PickerCallback; token: string }) => {
    if (googleDriveObject?.data?.docs?.length) {
      const filesToUpload: {
        id: string;
        name: string;
      }[] = [];
      const fileKeys: string[] = [];

      await Promise.all(
        googleDriveObject?.data?.docs.map(async file => {
          const fileToUpload = {
            type: 'application/pdf',
            name: `${file.name}.pdf`,
            lastModified: file.lastEditedUtc,
            size: file.sizeBytes,
            webkitRelativePath: '',
            lastModifiedDate: file.lastEditedUtc,
          };

          if (fileToUpload) {
            const fileKey = getDocumentsKey(userData.id, fileToUpload.name);
            const createdFileEntry = await createFile({ key: fileKey, name: fileToUpload.name });

            setUploadingFileId(createdFileEntry.id);

            filesToUpload.push({ id: createdFileEntry.id, name: createdFileEntry.name });
            fileKeys.push(fileKey);
          }
        })
      );

      const conversation = await assignFilesToConversation(filesToUpload);

      setIsUploadInProgress(true);
      setUploadingConversationId(conversation.id);
      setUploadProgress(0);

      navigate(`${Paths.CHAT}/${conversation?.id}`, { state: { dontRefresh: true } });

      const objToUpload = {
        documentIds: googleDriveObject?.data?.docs?.map(item => ({
          id: item.id,
        })),
        accessToken: googleDriveObject.token,
        fileKeys: fileKeys,
      };

      await uploadDriveFile({
        googleDriveObject: objToUpload,
        conversationId: conversation?.id,
        createdFiles: filesToUpload.map(item => item.id),
        googleDriveApi,
        fileApi,
        setIsUploadInProgress,
        cancelFileUpload,
        addToast,
        i18n,
      });
    }
  };

  const onUploadDocument = async (e?: React.ChangeEvent<HTMLInputElement>) => {
    const files = e?.target?.files;
    const file = files && files.length > 0 && files[0];

    if (!file) {
      return;
    }

    setFileToUpload(file);

    if (shouldSkipRedaction) {
      await handleUploadFile(file, true);

      return;
    }

    setIsAskForRedactionModalOpen(true);
  };

  const handleUploadFile = async (fileToUpload: File | undefined, skipRedaction: boolean) => {
    if (!fileToUpload) {
      return;
    }

    try {
      const fileKey = getDocumentsKey(userData.id, fileToUpload.name);
      const createdFileEntry = await createFile({ key: fileKey, name: fileToUpload.name, skipRedaction });
      const conversation = await assignFilesToConversation([{ id: createdFileEntry.id, name: createdFileEntry.name }]);

      setIsUploadInProgress(true);
      setUploadingConversationId(conversation.id);
      setUploadingFileId(createdFileEntry.id);
      setUploadProgress(0);
      navigate(`${Paths.CHAT}/${conversation.id}`, { state: { dontRefresh: true } });

      if (process.env.REACT_APP_USE_DOCUMENT_ENCRYPTION === 'true') {
        const status = await s3DocumentsApi.uploadDocuments(fileToUpload, createdFileEntry.id, {
          setProgress: setUploadProgress,
          setCancelToken: setCancelUploadToken,
        });

        if (status === 201) {
          setIsUploadInProgress(false);
          fileApi.startProcessing(conversation.id, createdFileEntry.id);
        }

        return;
      }

      const preSignedPutUrl = await s3DocumentsApi.generateDocumentsWriteUrl(fileKey, fileToUpload.type);

      const status = await uploadDocumentSecurely(preSignedPutUrl, fileToUpload, {
        setProgress: setUploadProgress,
        setCancelToken: setCancelUploadToken,
      });

      if (status === 200) {
        setIsUploadInProgress(false);
        fileApi.startProcessing(conversation.id, createdFileEntry.id);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Upload canceled');
        await cancelFileUpload();
      } else {
        console.error('Error during upload:', error);
      }
    }
  };

  const uploadDocument = (
    <Box sx={{ display: 'flex', gap: '8px' }}>
      {false && (
        <GoogleDrivePicker
          callback={onUploadGoogleDriveDocuments}
          disabled={disabled}
          googleDriveApi={googleDriveApi}
        />
      )}

      <MyLabel htmlFor={'upload-document'} disabled={disabled}>
        <IconContainer disabled={disabled}>
          <AttachFileIcon sx={{ width: '20px', height: '20px', fill: disabled ? COLOR_WHITE : COLOR_PRIMARY }} />
        </IconContainer>
      </MyLabel>
      {!disabled && (
        <MyInput
          type="file"
          ref={inputRef}
          id={'upload-document'}
          name={'upload-document'}
          onChange={onUploadDocument}
          onClick={(event: any) => {
            event.target.value = null;
          }}
          accept={
            'application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .md, .epub, ' +
            '.csv, application/JSON, text/plain, application/vnd.ms-excel, ' +
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          }
        />
      )}
      <RedactDataModal
        isOpen={isAskForRedactionModalOpen}
        onClose={() => setIsAskForRedactionModalOpen(false)}
        fileToUpload={fileToUpload}
        handleUploadFile={handleUploadFile}
      />
    </Box>
  );

  return disabled && disabledMessage ? (
    <BlackTooltip title={disabledMessage} placement={'top'} arrow>
      {uploadDocument}
    </BlackTooltip>
  ) : (
    uploadDocument
  );
};

export default UploadDocument;

import { default as Button, ButtonProps } from '@mui/material/Button';

import { COLOR_BORDER_PRIMARY } from '../../constants/colors';
import { LoadingSpinner } from '../spinner/LoadingSpinner';

interface TextButtonProps extends ButtonProps {
  loading?: boolean;
}

const TextButton = (props: TextButtonProps) => {
  const { loading, children, sx } = props;
  return (
    <Button
      variant={'text'}
      sx={{
        fontFamily: 'Poppins !important',
        lineHeight: '22px',
        fontSize: '14px',
        fontWeight: '600',
        height: '36px !important',
        ...sx,
      }}
      {...props}
    >
      {loading ? <LoadingSpinner color={COLOR_BORDER_PRIMARY} /> : children}
    </Button>
  );
};

export default TextButton;
